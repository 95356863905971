.quiz {
  margin: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  background-image: url("./bg-quiz.jpg"); /* Set the background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */

  .grid-container {
    min-height: 100svh;
    display: grid;
    grid-template-rows: auto 1fr auto; /* Header, Body, Footer */
    max-width: 375px;
    margin: auto;
  }

  .header {
    padding: 20px;
    height: 100px;
    span {
      display: block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      color: white;
      font-size: 1.25rem;
    }
  }

  .body {
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    .label {
      display: block;
      font-size: 2rem;
      font-weight: bold;
      margin: 10px 0;
    }

    .question {
      display: block;
      font-size: 1.5rem;
      font-weight: bold;
      margin: 10px 0;
    }
    .text {
      display: block;
      font-size: 1.5rem;
      margin: 10px 0;
      line-height: 1.75;
    }
    img {
      // margin: 10px 0 0 0;
      max-width: 300px;
    }
    button {
      height: 50px;
      border-radius: 10px;
      font-size: 1.25rem;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
    }
    .form-check {
      margin-bottom: .5rem;
    }
  }

  .footer {
    padding: 20px;

    .btn-group {
      display: flex;
      justify-content: space-between;
      :first-child {
        background: none;
      }
      button {
        display: block;
        padding: 20px 30px;
        border: none;
        border-radius: 30px;
        text-transform: uppercase;
        font-weight: bold;
        // :hover {
        //   background-color: red !important;
        //   transition: 0.5s;
        // }
      }
    }
  }
}
