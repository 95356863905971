.design {
  img {
    max-width: 100%;
  }

  input, label {
    //width: 100%;
    box-shadow: none !important;
    border-radius: unset !important;
    margin: 10px 10px 0 0  !important;
    padding: 0 !important;
  }

  .btn {
    width: 100%;
    margin-top: 20px;
  }

  .editedImage {
    border: 1px solid lightgray;
    margin: 10px 0;
  }

  .rounded {
    border-radius: 50% !important;
  }
}

